var card;
let letterEditor;
var signaturePad;

var letterEditorColors =  [
                            { color: '#000000', label: 'Black' },
                            { color: '#4d4d4d', label: 'Dim grey' },
                            { color: '#999999', label: 'Grey' },
                            { color: '#e6e6e6', label: 'Light grey' },
                            { color: '#ffffff', label: 'White', hasBorder: true },
                            { color: '#e64c4c', label: 'Red' },
                            { color: '#e7994c', label: 'Orange' },
                            { color: '#e6e64b', label: 'Yellow' },
                            { color: '#99e64c', label: 'Light green' },
                            { color: '#4de64c', label: 'Green' },
                            { color: '#4ce798', label: 'Aquamarine' },
                            { color: '#4de6e7', label: 'Turquoise' },
                            { color: '#4d99e7', label: 'Light blue' },
                            { color: '#4c4ce5', label: 'Blue' },
                            { color: '#994ce5', label: 'Purple' }
                          ]

document.addEventListener('turbolinks:load', () => {

  $('.zip-input').mask('00000-000');

  $("#order-recipients").on("click", ".representative", function(event) {
    $(this).toggleClass("selected");
    refreshOrderSummary();
  })

  ClassicEditor
    .create(document.querySelector('#letter'), {
      toolbar: [ 'heading', '|', 'alignment', '|', 'bold', 'italic', 'strikethrough', 'underline', 'highlight',
       '|', 'fontcolor', 'fontsize', '|', 'bulletedlist', 'indent', 'outdent', '|',
        'horizontalline', '|', 'removeformat', 'undo', 'redo', '|', '|' ],
      fontSize: {
        options: [
          9,
          11,
          13,
          'default',
          17,
          19,
          21
        ]
      },
      fontColor: {
        colors: letterEditorColors
      },
      fontBackgroundColor: {
        colors: letterEditorColors
      },
    })
    .then( newEditor => {
        letterEditor = newEditor;
    })
    .catch( error => {
        console.log(error);
    });

  var canvas = document.getElementById("signature");
  if (canvas != null) {
    signaturePad = new SignaturePad(canvas);
  }

  setupStripe();
})

document.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
});

window.enroll = function() {
  var first_name = $("[name='first_name']").val();
  var last_name = $("[name='last_name']").val();
  var email = $("[name='email']").val();

  window.location.href = "/user/orders/new?first_name="+first_name+"&last_name="+last_name+"&email="+email
}

window.setupStripe = function() {
  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      // Add your base input styles here. For example:
      fontSize: '16px',
      lineHeight: '24px'
    }
  };

  // Create an instance of the card Element
  card = elements.create('card', {style: style});

  // Add an instance of the card Element into the `card-element` <div>
  if ($("#card-element").length != 0) {
    card.mount('#card-element');
  };

  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });
}

window.validateParticulars = function() {
  return $("form#user-particulars-form").parsley().validate();
}

window.validateAddress = function() {
  return $("form#user-address-form").parsley().validate();
}

window.fetchRepresentatives = function() {
  var areParticularsValid = validateParticulars();
  var isAddressValid = validateAddress();
  if (areParticularsValid && isAddressValid) {
    var address = {
      "address": {
        "street": $("[name='street']").val(),
        "city": $("[name='city']").val(),
        "state": $("[name='state']").val(),
        "zip": $("[name='zip']").val()
      },
      "email": $("[name='email']").val(),
      "hillsborough": $("[name='hillsboroughCheck']").prop('checked')
    }
    $.ajax({
      type: 'POST',
      url: "/user/representatives/fetch",
      data: address,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function(data) {
        console.log(data);
      },
      error: function(request, error) {
        alert("Sorry. Something has gone wrong. Please try again.");
      }
    });
  }
}

window.refreshOrderSummary = function() {
  var orderSummaryHTML = "";
  $(".representative.selected").each(function(index, representative){
    var name = $(representative).data("name");
    var office = $(representative).data("office");
    orderSummaryHTML += '<div class="col-md-9">';
    orderSummaryHTML += 'Letter to '+ name +', '+ office;
    orderSummaryHTML += '</div>';
    orderSummaryHTML += '<div class="col-md-3 text-right">';
    orderSummaryHTML += '<strong>$5.00</strong>';
    orderSummaryHTML += '</div>';
  })
  var representativesCount = $(".representative.selected").length
  if (representativesCount > 0) {
    orderSummaryHTML += '<div class="col-md-9"></div>';
    orderSummaryHTML += '<div class="col-md-3 text-right">';
    orderSummaryHTML += '<hr><strong>$'+ representativesCount * 5 +'.00</strong>';
    orderSummaryHTML += '</div>';

    orderSummaryHTML += '<div class="col-md-12 text-right mt-4">';
    orderSummaryHTML += '<button type="button" id="order-submit" class="btn btn-primary" onclick="placeOrder()">Pay & Place Order</button>';
    orderSummaryHTML += '</div>';
  }

  $("#order-summary #order-items").empty();
  $("#order-summary #order-items").append(orderSummaryHTML);
}

window.validateRepresentatives = function() {
  var selectedRepresentatives = $(".representative.selected")
  if (selectedRepresentatives.length > 0) {
    return true
  } else {
    return false
  }
}

window.proceedToLetter = function() {
  var areRepresentativesValid = validateRepresentatives();
  if (areRepresentativesValid) {
    $("#proceed-to-letter-btn").addClass("d-none");
    $("#order-letter").removeClass("d-none");
    $('html, body').animate({
      scrollTop: $("#order-letter").offset().top
    }, 50);
  } else {
    alert("Please select atleast one representative before continuing.")
  }
}

window.validateLetter = function() {
  var letter = letterEditor.getData();
  if (letter.length > 0) {
    return true
  } else {
    return false
  }
}

window.validateSignature = function() {
  if (signaturePad.isEmpty()) {
    return false
  } else {
    return true
  }
}

window.clearSignature = function() {
  signaturePad.clear();
}

window.proceedToPayment = function() {
  var errorMessage = "";
  var isLetterValid = validateLetter();
  if (!isLetterValid) { errorMessage += "Please write a letter first." }
  var isSignatureValid = validateSignature();
  if (!isSignatureValid) { errorMessage += "Please add your signature to the letter." }
  if (isLetterValid && isSignatureValid) {
    $("#proceed-to-payment-btn").addClass("d-none");
    $("#order-payment").removeClass("d-none");
    $('html, body').animate({
      scrollTop: $("#order-payment").offset().top
    }, 50);
  } else {
    alert(errorMessage)
  }
}

window.placeOrder = function() {
  console.log("Start submit");
  $("#order-submit").addClass("disabled");
  validateSubmission();
}

window.validateSubmission = function() {
  var errorMessage = ""

  var areParticularsValid = validateParticulars();
  if (!areParticularsValid) { errorMessage += "Please add valid particulars first." }
  var isAddressValid = validateAddress();
  if (!isAddressValid) { errorMessage += "Please add a valid address first." }
  var areRepresentativesValid = validateRepresentatives();
  if (!areRepresentativesValid) { errorMessage += "Please select atleast one representative first." }
  var isLetterValid = validateLetter();
  if (!isLetterValid) { errorMessage += "Please write a letter first." }
  var isSignatureValid = validateSignature();
  if (!isSignatureValid) { errorMessage += "Please add your signature to the letter." }

  var isFormValid = (areParticularsValid && isAddressValid && areRepresentativesValid && isLetterValid && isSignatureValid)
  
  if (isFormValid) {
    createToken()
  } else {
    $("#order-submit").removeClass("disabled");
    alert(errorMessage)
  }
}

window.createToken = function() {
  var paymentForm = document.getElementById('payment-form');
  stripe.createToken(card).then(function(result) {
    if (result.error) {
      // Inform the user if there was an error
      var errorElement = document.getElementById('card-errors');
      errorElement.textContent = result.error.message;
      $("#order-submit").removeClass("disabled");
      return;
    } else {
      // Remove any previously created field
      if (paymentForm.elements["stripeToken"]){
        paymentForm.elements["stripeToken"].remove();
      } 
      // Send the token to your server
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', result.token.id);
      paymentForm.appendChild(hiddenInput);

      createOrder();
    }
  });
}

window.createOrder = function() {
  var payload = {}

  payload["person"] = {}
  var serializedParticulars = $("#user-particulars-form").serializeArray();
  $(serializedParticulars).each(function(i, v){
    payload["person"][v["name"]] = v["value"]
  })

  payload["address"] = {}
  var serializedAddress = $("#user-address-form").serializeArray();
  $(serializedAddress).each(function(i, v){
    payload["address"][v["name"]] = v["value"]
  })

  var representativesData = []

  $(".representative.selected").each(function(index, representative) {

    representativesData.push({
      "name": $(representative).data("name"),
      "office": $(representative).data("office"),
      "address": {
        "street": $(representative).data("street"),
        "city": $(representative).data("city"),
        "state": $(representative).data("state"),
        "zip": $(representative).data("zip")
      }
    })
  })

  payload["representatives"] = representativesData
  
  payload["letter"] = {
    "content": letterEditor.getData(),
    "signature": signaturePad.toDataURL("image/png")
  }

  $.ajax({
    type: 'POST',
    url: "/user/orders",
    data: payload,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    success: function(data) {
      console.log(data);
      create_charge(data.id);
    },
    error: function(request, error) {
      $("#order-submit").removeClass("disabled");
      alert("Sorry. Something has gone wrong. Please try again.");
    }
  });
}

window.create_charge = function(order_id) {
  var payload = $("#payment-form").serializeArray();
  payload.push({name: "order_id", value: order_id});
  $.ajax({
    type: 'POST',
    url: "/user/charges",
    data: payload,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    error: function() {
      $("#order-submit").removeClass("disabled");
      alert("Sorry. Something has gone wrong. Please try again.");
    },
    success: function(data) {
      window.location.href = "/user/orders/"+ data.id +"/success/";
    }
  });
}
